






















































































































































































































































































































































import Component, {mixins} from 'vue-class-component';
import BaseDialog from '@/components/BaseDialog.vue';
import {Prop, Watch} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {applicationStore} from '@/store/modules/application';
import ContactOptionCard from '@/components/directory/ContactOptionCard.vue';
import ContactsList from '@/components/ContactsList.vue';
import SearchView from '@/components/SearchView.vue';
import {businessStore} from '@/store/modules/business/businessStore';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {Getter} from 'vuex-class';
import Notifications from '@/components/mixins/Notifications';
import {tourStore} from "@/store/modules/tour";
import {BusinessContact, RolesList} from "@/domain/model/types";
import {profileStore} from "@/store/modules/profile";

@Component({
  name: 'dialog-create-custom-contact',
  components: {SearchView, ContactsList, ContactOptionCard, AvatarWithStatus, BaseDialog}
})
export default class DialogCreateCustomContact extends mixins(Notifications) {
  @Prop() value!: boolean;

  currentStep?: string = 'options';
  stepOptions: string = 'options';
  stepAssignAssociate: string = 'assign-associate';
  stepCreateCustomContact: string = 'create-custom';
  stepAssignContacts: string = 'assign-contacts';
  stepCreateGroupContact: string = 'create-group';

  history: (string | undefined)[] = [];

  customSelected: boolean = false;
  groupSelected: boolean = false;
  useProfileData: boolean = false;
  defaultContact: boolean = false;

  associates: { data: any, selected: boolean }[] = [];
  associatesOriginal: { data: any, selected: boolean }[] = [];
  customContacts: { data: any, selected: boolean }[] = [];
  customContactsOriginal: { data: any, selected: boolean }[] = [];

  contactImageFile: File | null = null;
  contactImageUrl: string | null = null;
  contactName: string = '';
  contactPosition: string = '';
  contactDescription: string = '';
  contactEmail: string = '';
  contactPhoneNumber: string = '';
  emptyContactHelper: boolean = false

  @Getter contacts;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    if (tourStore.tourAction) {
      tourStore.setTourAction(false)
      tourStore.setTour(tourStore.tourNumber - 1)
    }
    this.$emit('input', value);
  }

  get busy() {
    return directoryStore.loading;
  }

  get t2bUser() {
    return profileStore.t2bUser
  }

  get business() {
    return applicationStore.business;
  }

  get isOwner() {
    return applicationStore.isOwner;
  }

  get title() {
    switch (this.currentStep) {
      case this.stepOptions:
        return 'Create New Contact';
      case this.stepAssignAssociate:
        return 'Assign Associate to Custom Contact';
      case this.stepAssignContacts:
        return 'Assign Contacts to Group Contact';
      case this.stepCreateCustomContact:
        return 'Complete Custom Contact Profile';
      case this.stepCreateGroupContact:
        return 'Complete Group Contact Profile';
      default:
        return '';
    }
  }

  get subtitle() {
    switch (this.currentStep) {
      case this.stepOptions:
        return 'Select the type of a contact you want to create';
      case this.stepAssignAssociate:
        return 'Choose specific associate for this contact';
      case this.stepAssignContacts:
        return 'Choose specific contacts for this group';
      case this.stepCreateCustomContact:
      case this.stepCreateGroupContact:
        return 'Fill down the form to create contact';
      default:
        return '';
    }
  }

  get contactItems() {
    return this.customContacts;
  }

  get includedContacts() {
    return this.customContacts.reduce((count, item) => {
      return item.selected ? ++count : count;
    }, 0);
  }

  get associateItems() {
    return this.associates;
  }

  get assignedAssociate() {
    return this.associateItems.find(item => item.selected);
  }

  get defaultContactId() {
    return this.assignedAssociate?.data?.defaultContactId;
  }

  get assignedAssociatePhoto() {
    return this.assignedAssociate?.data?.photoUrl?.normal;
  }

  get assignedAssociateOnline() {
    return this.assignedAssociate?.data?.status?.online;
  }

  get assignedAssociateName() {
    return this.assignedAssociate?.data?.fullName;
  }

  get assignedAssociatePosition() {
    return this.assignedAssociate?.data?.position;
  }

  get assignedAssociateEmail() {
    return this.assignedAssociate?.data?.email;
  }

  get assignedAssociatePhone() {
    return this.assignedAssociate?.data?.phoneNumber;
  }

  get onCreateContact() {
    return this.customSelected ? this.onCreateCustomContact : this.onCreateGroupContact;
  }

  get tip() {
    const emptyContact = this.emptyContactHelper ? '<br>Add individual contacts first' : ''
    return this.customSelected ?
        'Custom contacts are for individual users.<br>' +
        'You can use the associate\'s profile information for<br>' +
        'the contact or create custom contact information' :
        `Group contacts are for one<br>or more individual contacts ${emptyContact}`
  }

  get disableContinueToCreate():boolean {
    if (!this.assignedAssociate &&
        this.currentStep === this.stepAssignAssociate) {
      return true
    }
    if (!this.includedContacts &&
        this.currentStep === this.stepAssignContacts) {
      return true
    }
    return false
  }

  photoUrl(item) {
    return item.photoUrl?.normal;
  }

  isOnline(contact) {
    const item = contact.associate || contact;
    const status = item.status;
    return status && status.online;
  }

  releaseAssignedAssociate() {
    this.onAssociateSelected(this.assignedAssociate);
  }

  onAssociateSelected(associate) {
    const selected = this.associates.find((item) => item.selected);
    if (selected) {
      const indexOf = this.associates.indexOf(selected);
      selected.selected = false;
      this.associates.splice(indexOf, 1, selected);
    }
    if (selected && selected.data.id === associate.data.id) {
      return;
    }
    associate.selected = !associate.selected;
    const found = this.associates.find((item) => item.data.id === associate.data.id);
    if (found) {
      this.associates.splice(this.associates.indexOf(found), 1, associate);
    }
  }

  onContactSelected(contact) {
    const indexOf1 = this.customContacts.indexOf(contact);
    const indexOf2 = this.customContactsOriginal.indexOf(contact);
    const item = {...contact, selected: !contact.selected};
    this.customContacts.splice(indexOf1, 1, item);
    this.customContactsOriginal.splice(indexOf2, 1, item);
  }

  onContactImageFileSelected(event) {
    this.contactImageFile = event.target.files[0];
    if (this.contactImageFile) {
      console.log(this.contactImageFile.name);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.contactImageUrl = e.target.result || null;
      };
      reader.readAsDataURL(this.contactImageFile);
    }
  }

  onCustomSelected() {
    this.customSelected = true;
    this.groupSelected = false;
  }

  onGroupSelected() {
    this.customSelected = false;
    this.groupSelected = true;
  }

  onContinueToAssignment() {
    this.history.push(this.currentStep);
    this.currentStep = this.customSelected ? this.stepAssignAssociate : this.stepAssignContacts;
  }

  onContinueToCreate() {
    if (this.customSelected) {
      this.contactName = this.useProfileData ? this.assignedAssociateName : '';
      this.contactPosition = this.useProfileData ? this.assignedAssociatePosition : '';
      this.contactEmail = this.useProfileData ? this.assignedAssociateEmail : '';
      this.contactPhoneNumber = this.useProfileData ? this.assignedAssociatePhone : '';
      this.contactImageUrl = this.useProfileData ? this.assignedAssociatePhoto : '';
    }
    this.history.push(this.currentStep);
    this.currentStep = this.customSelected ? this.stepCreateCustomContact : this.stepCreateGroupContact;
  }

  async onCreateCustomContact() {
    const form: any = this.$refs.contactInfo;
    if (!form.validate()) {
      return;
    }
    const {
      assignedAssociate,
      contactName,
      contactPosition,
      contactEmail,
      contactPhoneNumber,
      contactImageFile,
      contactImageUrl,
      defaultContact
    } = this;
    const contact: BusinessContact | null = await directoryStore.createCustomContact({
      associate: assignedAssociate!.data,
      contactData: {
        name: contactName,
        position: contactPosition,
        email: contactEmail,
        phoneNumber: contactPhoneNumber?.replace(/[^0-9]/g, ''),
        photoFile: contactImageFile,
        useProfilePhotoUrl: !!contactImageUrl
      },
      defaultContact
    });
    tourStore.setTourAssociate(contact)
    tourStore.setTourAction(false)
    this.showInfo('Custom contact has been created');
    this.show = false;
  }

  async onCreateGroupContact() {
    const form: any = this.$refs.groupContactInfo;
    if (!form.validate()) {
      return;
    }
    const {contactName, contactDescription, contactEmail, contactPhoneNumber, contactImageFile} = this;
    const contact: BusinessContact | null = await directoryStore.createGroupContact({
      contacts: this.contactItems.filter(item => item.selected).map(item => item.data),
      contactData: {
        name: contactName.trim(),
        description: contactDescription,
        email: contactEmail,
        phoneNumber: contactPhoneNumber.replace(/[^0-9]/g, ''),
        photoFile: contactImageFile
      }
    });
    tourStore.setTourAssociate(contact)
    tourStore.setTourAction(false)
    this.showInfo('Group contact has been created');
    this.show = false;
  }

  stepBack() {
    this.currentStep = this.history.pop();
  }

  searchAssociates(term) {
    if (term === null || term === '') {
      this.associates = this.associatesOriginal;
      return;
    }
    const searchString = term.toLowerCase();
    this.associates = this.associatesOriginal.filter((item) => {
      return item.data.fullName.toLowerCase().startsWith(searchString);
    });
  }

  searchContacts(term) {
    if (term === null || term === '') {
      this.customContacts = this.customContactsOriginal;
      return;
    }
    const searchString = term.toLowerCase();
    this.customContacts = this.customContactsOriginal.filter((item) => {
      return item.data.name.toLowerCase().startsWith(searchString);
    });
  }

  async created() {
    await businessStore.loadAssociates();
    const mapFunc = (item) => {
      return {data: item, selected: false};
    };
    const filterFuncUser = (item) => item.data.id === this.t2bUser.id
    const filterFuncContact = (item) => item.data.associate.id === this.t2bUser.id

    const isTour: boolean = await tourStore.checkTour({tourNumber: 3, role: RolesList.admin})
    if (isTour) {
      this.associates = businessStore.associates.map(mapFunc).filter(filterFuncUser)
    } else {
      this.associates = businessStore.associates.map(mapFunc)
    }
    this.associatesOriginal = Object.assign([], this.associates);
    if (isTour) {
      this.customContacts = this.contacts.filter((item) => item.type === 1).map(mapFunc).filter(filterFuncContact)
    } else {
      this.customContacts = this.contacts.filter((item) => item.type === 1).map(mapFunc)
    }
    this.customContactsOriginal = Object.assign([], this.customContacts);
    if (this.associateItems.length <= 1 && !isTour) {
      this.emptyContactHelper = true
    }
  }
}
